import axios from 'axios';
import config from 'config';

const api = axios.create({
	baseURL: config.apiUrl,
});

export const fetcher = async (url, options) => {
	let fetch = api.get(url);
	if (options) {
		fetch = api.get(url, options);
	}
	const response = await fetch;
	if (response.data.error) {
		// throw error with message from server
		throw new Error(response.data.message);
	}
	return response.data;
};

export default api;
