import React from 'react';

import { store } from 'react-notifications-component';

import Icon from 'components/icon/Icon';

const _settings = {
	insert: 'top',
	container: 'top-right',
	animationIn: ['animate__animated', 'animate__fadeIn'],
	animationOut: ['animate__animated', 'animate__fadeOut'],
	dismiss: {
		duration: 3000,
		pauseOnHover: true,
		onScreen: true,
		showIcon: true,
		waitForAnimation: true,
	},
};

const showNotification = (title, message, type = 'default') => {
	store.addNotification({
		title,
		message,
		type,
		..._settings,
	});
};

export const showSuccessNotification = (icon, message, title) => {
	showNotification(
		<span className='d-flex align-items-center'>
			<Icon color='success' icon={icon} size='4x' />
			<p className='display-6 ps-1 mb-0'>{title}</p>
		</span>,
		message,
	);
};

export const showErrorNotification = (icon, message, title) => {
	showNotification(
		<span className='d-flex align-items-center'>
			<Icon color='danger' icon={icon} size='4x' />
			<p className='display-6 ps-1 mb-0'>{title}</p>
		</span>,
		message,
	);
};

export default showNotification;
