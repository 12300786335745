import React, { useContext, useRef } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import Brand from 'layout/Brand/Brand';
import Navigation from 'layout/Navigation/Navigation';
import User from 'layout/User/User';

import Tooltips from 'components/bootstrap/Tooltips';

import ThemeContext from 'contexts/themeContext';

import useAsideTouch from 'hooks/useAsideTouch';
import useDarkMode from 'hooks/useDarkMode';

import { normalAdminMenu, superAdminMenu } from '../../menu';
import { USER_TYPE } from 'common/constants/users';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { user } = useSelector((s) => s.auth);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } =
		useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	// eslint-disable-next-line no-unused-vars
	const { darkModeStatus } = useDarkMode();

	return (
		<>
			<motion.aside
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close':
							!touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open':
							touchStatus && hasTouchButton && isModernDesign,
					},
				)}
				style={asideStyle}
			>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					{user.role === USER_TYPE.ADMIN && <Navigation id='aside-dashboard' menu={normalAdminMenu} />}
					{user.role === USER_TYPE.SUPER_ADMIN && <Navigation id='aside-dashboard' menu={superAdminMenu} />}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>{/* nothing here */}</div>
					</nav>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips flip={['top', 'right']} title='Toggle Aside'>
						<motion.div
							className='aside-touch'
							drag='x'
							dragConstraints={constraintsRef}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
