import { handleActions } from 'redux-actions';

import { LOGIN_SUCCESS, SIGN_OUT } from '../constants';

// const { REACT_APP_LOGGED_IN } = process.env;
// const REACT_APP_LOGGED_IN = false;

const initialState = {
	isLoggedIn: null,
	accessToken: null,
	refreshToken: null,
	user: null,
};

export default handleActions(
	{
		[LOGIN_SUCCESS]: (state, action) => {
			const { user } = action.payload;
			// eslint-disable-next-line camelcase
			const display_name = [user.name, user.email, user.phone].filter(
				Boolean,
			)[0];
			return {
				...state,
				isLoggedIn: true,
				accessToken: action.payload.access_token,
				refreshToken: action.payload.refresh_token,
				user: { ...user, display_name },
			};
		},
		[SIGN_OUT]: () => initialState,
	},
	initialState,
);
