import React from 'react';

import { useSelector } from 'react-redux';

import Avatar from 'components/Avatar';

import USERS from 'common/data/userDummyData';

const HeaderAvatar = () => {
	const { user } = useSelector((s) => s.auth);

	return (
		<div className='col d-flex align-items-center cursor-pointer'>
			<Avatar
				color={USERS.JOHN.color}
				size={48}
				src={USERS.JOHN.src}
				srcSet={USERS.JOHN.srcSet}
			/>
			<div className='ms-3'>
				<div className='text-start'>
					<div className='fw-bold fs-6 mb-0'>{user.display_name}</div>
					<div className='text-muted'>
						<small>{user.role}</small>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderAvatar;
