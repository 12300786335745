import React, { forwardRef, useContext, useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import ThemeContext from '../../contexts/themeContext';
import useDeviceScreen from '../../hooks/useDeviceScreen';
import useEventListener from '../../hooks/useEventListener';
import Portal from '../../layout/Portal/Portal';
import TagWrapper from '../TagWrapper';

export const OffCanvasTitle = forwardRef(
	({ tag, id, children, className, ...props }, ref) => {
		return (
			<TagWrapper
				className={classNames('offcanvas-title', className)}
				id={id}
				ref={ref}
				tag={tag}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
			</TagWrapper>
		);
	},
);
OffCanvasTitle.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span']),
};
OffCanvasTitle.defaultProps = {
	className: null,
	tag: 'h5',
};

export const OffCanvasHeader = forwardRef(
	({ children, className, setOpen, ...props }, ref) => {
		return (
			<div
				className={classNames('offcanvas-header', className)}
				ref={ref}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
				{setOpen && (
					<button
						aria-label='Close'
						className='btn-close text-reset'
						data-bs-dismiss='offcanvas'
						type='button'
						onClick={() => setOpen(false)}
					/>
				)}
			</div>
		);
	},
);
OffCanvasHeader.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	setOpen: PropTypes.func,
};
OffCanvasHeader.defaultProps = {
	className: null,
	setOpen: null,
};

export const OffCanvasBody = forwardRef(
	({ tag, children, className, ...props }, ref) => {
		return (
			<TagWrapper
				className={classNames('offcanvas-body', className)}
				ref={ref}
				tag={tag}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
			</TagWrapper>
		);
	},
);
OffCanvasBody.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	tag: PropTypes.oneOf(['div', 'span', 'section', 'form']),
};
OffCanvasBody.defaultProps = {
	className: null,
	tag: 'div',
};

const OffCanvas = ({
	id,
	titleId,
	children,
	placement,
	isBodyScroll,
	isBackdrop,
	isModalStyle,
	isOpen,
	setOpen,
	isRightPanel,
	tag: Tag,
	...props
}) => {
	const initialProps = {
		isBackdrop: isRightPanel ? false : isBackdrop,
		isBodyScroll: isRightPanel ? true : isBodyScroll,
		placement: isRightPanel ? 'end' : placement,
	};

	const { setRightPanel } = useContext(ThemeContext);
	const deviceScreen = useDeviceScreen();

	useLayoutEffect(() => {
		setRightPanel(isRightPanel && deviceScreen?.width > 1200 && isOpen);
	});

	const ref = useRef(null);

	// Disable Body Scroll
	useLayoutEffect(() => {
		if (!initialProps.isBodyScroll && isOpen) {
			document.body.style.overflow = 'hidden';
			document.body.style.paddingRight = '0px';
		}
		return () => {
			document.body.style.overflow = 'auto';
			document.body.style.removeProperty('padding-right');
		};
	});

	// Backdrop close function
	const closeCanvas = (event) => {
		if (
			ref.current &&
			!ref.current.contains(event.target) &&
			!isRightPanel &&
			isBackdrop
		) {
			setOpen(false);
		}
	};
	useEventListener('mousedown', closeCanvas);
	useEventListener('touchstart', closeCanvas);

	const _placementAnimation = (initialProps.placement === 'start' && {
		x: '-100%',
	}) ||
		(initialProps.placement === 'top' && { y: '-100%' }) ||
		(initialProps.placement === 'bottom' && { y: '100%' }) || { x: '100%' };

	const MotionTagWrapper = motion[Tag];

	return (
		<Portal>
			<AnimatePresence exitBeforeEnter>
				{isOpen && (
					<>
						<MotionTagWrapper
							animate={{ opacity: 1, x: '0%', y: '0%' }}
							aria-labelledby={titleId}
							className={classNames(
								'offcanvas',
								`offcanvas-${initialProps.placement}`,
								{
									show: isOpen,
									'offcanvas-modal-style': isModalStyle,
									'offcanvas-right-panel':
										isRightPanel && deviceScreen?.width > 1200,
								},
							)}
							data-bs-backdrop={initialProps.isBackdrop}
							data-bs-scroll={initialProps.isBodyScroll}
							exit={{ opacity: 0, ..._placementAnimation }}
							id={id}
							initial={{ opacity: 0, ..._placementAnimation }}
							key='offCanvas'
							ref={ref}
							style={{ visibility: isOpen && 'visible' }}
							tabIndex='-1'
							transition={{ ease: 'easeInOut', duration: 0.3 }}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...props}
						>
							{children}
						</MotionTagWrapper>
						{initialProps.isBackdrop && (
							<div
								className={classNames('offcanvas-backdrop', 'fade', 'show')}
							/>
						)}
					</>
				)}
			</AnimatePresence>
		</Portal>
	);
};
OffCanvas.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node.isRequired,
	placement: PropTypes.oneOf(['start', 'top', 'end', 'bottom']),
	titleId: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	isBodyScroll: PropTypes.bool,
	isBackdrop: PropTypes.bool,
	isModalStyle: PropTypes.bool,
	isRightPanel: PropTypes.bool,
	tag: PropTypes.oneOf(['div', 'section', 'form']),
};
OffCanvas.defaultProps = {
	id: null,
	placement: 'end',
	titleId: null,
	isBodyScroll: false,
	isBackdrop: true,
	isModalStyle: false,
	isRightPanel: false,
	tag: 'div',
};

export default OffCanvas;
