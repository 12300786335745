import React, { useState } from 'react';

import { Formik } from 'formik';
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { Link, useHistory, Redirect } from 'react-router-dom';
import * as Yup from 'yup';

import Page from 'layout/Page/Page';
import PageWrapper from 'layout/PageWrapper/PageWrapper';

import Checks, { ChecksGroup } from 'components/bootstrap/forms/Checks';
import AuthCard from 'components/card/AuthCard';
import { PrimaryButton } from 'components/CommonButton';
import InputField from 'components/form/InputField';

import { changePassword } from 'redux/actions/auth';

const NewPasswordPage = () => {
	const [errorMessage, setErrorMessage] = useState(null);
	const [isPasswordHidden, setPasswordHidden] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleChange = () => {
		setPasswordHidden(!isPasswordHidden);
	};

	// check history data
	const { username } = qs.parse(history.location.search, { decode: false });
	const { allow, session } = history.location.state || '';

	// Parsed URL username
	const user = String(username);

	const validate = Yup.object({
		new_password: Yup.string()
			.min(10, 'Password must be atleast 10 characters long')
			.max(30, 'Password must not be more than 30 characters long')
			.matches(/^[\S]{10,}$/, 'Invalid Password')
			.required('Required'),
	});

	// if history data invalid Redirect
	if (!allow && username === undefined) return <Redirect to='/' />;

	return (
		<PageWrapper className='bg-primary' title='First Login'>
			<Page className='p-0'>
				<AuthCard
					mainText='First Login'
					subText='Create your new password to continue!'
				>
					<Formik
						initialValues={{
							username: user,
							new_password: '',
							session: String(session),
						}}
						validationSchema={validate}
						onSubmit={(formValues) => {
							// console.log(formValues);
							setErrorMessage(null);
							return dispatch(changePassword(formValues)).then((response) => {
								if (response.success) {
									history.push('/');
								}
								if (!response.success) {
									setErrorMessage(response.error);
								}
							});
						}}
					>
						{({ handleSubmit, isSubmitting }) => (
							<form className='row g-4' onSubmit={handleSubmit}>
								<div className='col-12'>
									<InputField
										disabled
										label='Email'
										name='username'
										placeholder='ali@email.com'
									/>
									<InputField
										label='New Password'
										name='new_password'
										placeholder='**********'
										type={isPasswordHidden ? 'text' : 'password'}
									/>
								</div>
								<ChecksGroup className='pb-1'>
									<Checks
										checked={isPasswordHidden}
										label='Show Password'
										type='switch'
										value=''
										onChange={handleChange}
									/>
								</ChecksGroup>
								<div className='text-danger'>{errorMessage}</div>

								<div className='col-12'>
									<PrimaryButton
										className='w-100 py-3 mb-3'
										isLoading={isSubmitting}
										label='Confirm'
										type='submit'
									/>
									<Link className='text-center' to='/'>
										<p>Back</p>
									</Link>
								</div>
							</form>
						)}
					</Formik>
				</AuthCard>
			</Page>
		</PageWrapper>
	);
};

export default NewPasswordPage;
