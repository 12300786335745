import api from './index';

const apiSubscription = (store) => {
	let hasAccessToken = false;

	const updateAPI = (auth) => {
		let needsUpdate = false;
		const storeHasAccessToken = !!auth.accessToken;

		if (hasAccessToken !== storeHasAccessToken) {
			hasAccessToken = storeHasAccessToken;
			needsUpdate = true;
		}

		if (!needsUpdate) return;

		if (hasAccessToken) {
			api.defaults.headers = {
				Authorization: `Bearer ${auth.accessToken}`,
			};
		} else {
			api.defaults.headers = {};
		}
	};

	// Uncomment if we eventually unsubscribe, needed to comment assignment
	// to prevent linting errors.
	// const unsubscribe =
	store.subscribe(() => {
		const { auth } = store.getState();
		updateAPI(auth);
		// checkIfSessionEnded(navigation);
	});
};

export default apiSubscription;
