import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';

import { getOS } from 'helpers/helpers';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';
import { SWRConfig } from 'swr';

import Aside from 'layout/Aside/Aside';
import Portal from 'layout/Portal/Portal';
import Wrapper from 'layout/Wrapper/Wrapper';

import { Toast, ToastContainer } from 'components/bootstrap/Toasts';

import ThemeContext from 'contexts/themeContext';

import useDarkMode from 'hooks/useDarkMode';

import COLORS from 'common/data/enumColors';

function App() {
	getOS();

	const { isLoggedIn } = useSelector((s) => s.auth);
	const location = useLocation();

	/**
	 * Dark Mode
	 */
	const { darkModeStatus, themeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */

	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESIGN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	// const withOutAsidePages = ['/auth/login'];

	// To check cache keys
	// const { cache } = useSWRConfig();

	// eslint-disable-next-line no-console
	// console.log('cache', cache);

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<SWRConfig
					value={{
						shouldRetryOnError: false,
						revalidateOnFocus: false,
						dedupingInterval: 10000,
					}}
				>
					<div
						className='app'
						ref={ref}
						style={{
							backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
							zIndex: fullScreenStatus && 1,
						}}
					>
						<Switch location={location}>
							{!isLoggedIn && <Route component={Wrapper} path='*' />}
							<Route>
								<Aside />
								<Wrapper />
							</Route>
						</Switch>
					</div>
				</SWRConfig>
				<Portal id='portal-notification'>
					<ReactNotification />
				</Portal>
			</ToastProvider>
		</ThemeProvider>
	);
}

export default App;
