import React from 'react';

import PropTypes from 'prop-types';

import Card, { CardBody } from 'components/bootstrap/Card';
import Image from 'components/Image';

const AuthCard = ({ children, mainText, subText }) => {
	return (
		<div className='row h-100 align-items-center justify-content-center'>
			<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
				<Card className='shadow-3d-dark' data-tour='login-page'>
					<CardBody>
						<div className='text-center my-4'>
							<Image alt='mirath logo' width='55%' />
						</div>
						<div className='my-3'>
							<div className='text-center h1 fw-bold'>{mainText}</div>
							<div className='text-center h4 text-muted'>{subText}</div>
						</div>
						{/* Input form goes here */}
						{children}
					</CardBody>
				</Card>
				<div className='text-center'>
					<a className='link-dark text-decoration-none me-3' href='/'>
						Privacy policy
					</a>
					<a className='link-dark text-decoration-none' href='/'>
						Terms of use
					</a>
				</div>
			</div>
		</div>
	);
};

AuthCard.propTypes = {
	children: PropTypes.node,
	mainText: PropTypes.string,
	subText: PropTypes.string,
};

AuthCard.defaultProps = {
	children: null,
	mainText: '',
	subText: '',
};

export default AuthCard;
