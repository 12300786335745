import React, { lazy, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import AcceptInvitePage from 'container/accept-invite/AcceptInvitePage';
import ForgotPasswordPage from 'container/login/ForgotPasswordPage';
import LoginPage from 'container/login/LoginPage';
import NewPasswordPage from 'container/login/NewPasswordPage';
import ResetPasswordPage from 'container/login/ResetPasswordPage';

import Spinner from 'components/bootstrap/Spinner';

import { checkHasUser } from 'redux/actions/auth';

const HOME = {
	DASHBOARD: lazy(() =>
		import('container/authenticated/dashboard/DashboardPage'),
	),
	SUPER_ADMIN_MANAGEMENT: lazy(() =>
		import('container/authenticated/administrators/SuperAdminManagementPage'),
	),
	NORMAL_ADMIN_MANAGEMENT: lazy(() =>
		import('container/authenticated/administrators/NormalAdminManagementPage'),
	),
};

// MJK
const MJK = {
	DASHBOARD: lazy(() =>
		import('container/authenticated/mjk/dashboard/DashboardPage'),
	),
	MASJID_MAIN: lazy(() =>
		import('container/authenticated/mjk/masjid/MasjidMainPage'),
	),
	MASJID_VIEW: lazy(() =>
		import('container/authenticated/mjk/masjid/edit/MasjidViewPage'),
	),
	ORDERS_MAIN: lazy(() =>
		import('container/authenticated/mjk/orders/OrdersMainPage'),
	),
	ORDERS_VIEW: lazy(() =>
		import('container/authenticated/mjk/orders/edit/OrdersViewPage'),
	),
	ADD_ORDERS: lazy(() =>
		import('container/authenticated/mjk/orders/add/AddOrderPage'),
	),
	KIT_MAIN: lazy(() => import('container/authenticated/mjk/kit/KitMainPage')),
	KIT_VIEW: lazy(() =>
		import('container/authenticated/mjk/kit/edit/KitViewPage'),
	),
	VERIFY_MAIN: lazy(() =>
		import('container/authenticated/mjk/verify/VerifyMainPage'),
	),
	CONTACT_MAIN: lazy(() =>
		import('container/authenticated/mjk/contact/ContactMainPage'),
	),
};

const FARAID = {
	USER_MAIN: lazy(() =>
		import('container/authenticated/faraid/users/UserMainPage'),
	),
	SIMPLE_PLAN_MAIN: lazy(() =>
		import('container/authenticated/faraid/simple-plan/PlanMainPage'),
	),
	SIMPLE_PLAN_VIEW: lazy(() =>
		import('container/authenticated/faraid/simple-plan/edit/PlanViewPage'),
	),
};

const BASIC = {
	LOGIN: LoginPage,
	ACCEPT_INVITE: AcceptInvitePage,
	FORGOT: ForgotPasswordPage,
	RESET: ResetPasswordPage,
	NEW_PASSWORD: NewPasswordPage,
};

const AUTH = {
	PAGE_404: lazy(() => import('container/Page404')),
	COMING_SOON: lazy(() => import('container/ComingSoonPage')),
};

// src/menu.js - sidebar paths
const basicRoutes = [
	{ path: '/accept-invite', component: BASIC.ACCEPT_INVITE },
	{ path: '/login', component: BASIC.LOGIN },
	{ path: '/', component: BASIC.LOGIN },
	{ path: '/forgot-password', component: BASIC.FORGOT },
	{ path: '/reset-password', component: BASIC.RESET },
	{ path: '/new-password', component: BASIC.NEW_PASSWORD },
];

// This only contains pages accessible by super admin
const superAdminRoutes = [
	{
		path: '/administrator-management/super-admin',
		component: HOME.SUPER_ADMIN_MANAGEMENT,
	},
	{
		path: '/administrator-management/normal-admin',
		component: HOME.NORMAL_ADMIN_MANAGEMENT,
	},
];

// By default, Super Admins can access normal admin pages
const adminRoutes = [
	{ path: '/', component: HOME.DASHBOARD },
	// MJK
	{ path: '/mjk/dashboard', component: MJK.DASHBOARD },
	// Masjid
	{ path: '/mjk/masjid', component: MJK.MASJID_MAIN },
	{ path: '/mjk/masjid/:id', component: MJK.MASJID_VIEW },
	// Orders
	{ path: '/mjk/orders', component: MJK.ORDERS_MAIN },
	{ path: '/mjk/orders/add', component: MJK.ADD_ORDERS },
	{ path: '/mjk/orders/:id', component: MJK.ORDERS_VIEW },
	// Kit
	{ path: '/mjk/kit', component: MJK.KIT_MAIN },
	{ path: '/mjk/kit/:id', component: MJK.KIT_VIEW },
	//Verify
	{ path: '/mjk/verify', component: MJK.VERIFY_MAIN },
	// Contact Us
	{ path: '/mjk/contact', component: MJK.CONTACT_MAIN },
	// Faraid
	{ path: '/faraid', component: AUTH.COMING_SOON },
	{ path: '/faraid/users', component: FARAID.USER_MAIN },
	{ path: '/faraid/simple-plan', component: FARAID.SIMPLE_PLAN_MAIN },
	{ path: '/faraid/simple-plan/:id', component: FARAID.SIMPLE_PLAN_VIEW },
	// Hibah
	{ path: '/hibah', component: AUTH.COMING_SOON },
	// Wasiat
	{ path: '/wasiat', component: AUTH.COMING_SOON },
];

const ContentRoutes = () => {
	const location = useLocation();
	const { isLoggedIn, user } = useSelector((s) => s.auth);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkHasUser()).finally(() => setIsLoading(false));
		// Cleanup function prevent memory leak issues or function stopped halfway
		return () => {
			setIsLoading(false);
		};
	}, [dispatch]);

	const routes = useMemo(() => {
		let array = [];
		if (!isLoggedIn) {
			return array.concat(basicRoutes);
		}
		if (user.role === 'Superadmin') {
			array = array.concat(superAdminRoutes);
			array = array.concat(adminRoutes);
		} else {
			array = array.concat(adminRoutes);
		}
		return array;
	}, [isLoggedIn, user]);

	if (isLoading) {
		return (
			<div className='vh-100 vw-100 d-flex align-items-center justify-content-center'>
				<Spinner color='primary' size='5vh' />
			</div>
		);
	}

	return (
		<Switch location={location}>
			{routes.map(({ path, component }) => (
				<Route exact component={component} key={path} path={path} />
			))}
			<Route component={AUTH.PAGE_404} />
		</Switch>
	);
};

export default ContentRoutes;
