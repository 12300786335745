import React, { useState } from 'react';

import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Page from 'layout/Page/Page';
import PageWrapper from 'layout/PageWrapper/PageWrapper';

import AuthCard from 'components/card/AuthCard';
import { PrimaryButton } from 'components/CommonButton';
import InputField from 'components/form/InputField';

import { forgotPasswordCheck } from 'redux/actions/auth';

const ForgotPasswordPage = () => {
	const [errorMessage, setErrorMessage] = useState(null);
	const dispatch = useDispatch();
	const history = useHistory();

	const validate = Yup.object({
		username: Yup.string().email('Invalid Email').required('Required'),
	});

	return (
		<PageWrapper className='bg-primary' title='Forgot Password'>
			<Page className='p-0'>
				<AuthCard
					mainText='Forgot Password'
					subText='Enter your email address!'
				>
					<Formik
						initialValues={{
							username: '',
						}}
						// innerRef={formRef}
						validationSchema={validate}
						onSubmit={(formValues) => {
							setErrorMessage(null);
							return dispatch(forgotPasswordCheck(formValues)).then(
								({ error, needCompleteSignup, success }) => {
									if (success && needCompleteSignup) {
										setErrorMessage(
											'Please contact Administrator to receive your temporary password!',
										);
									}
									if (success && !needCompleteSignup) {
										history.push({
											pathname: '/reset-password',
											search: `?username=${formValues.username}`,
											state: { from: history.location.pathname },
										});
									}
									if (!success) {
										setErrorMessage(error);
									}
								},
							);
						}}
					>
						{({ handleSubmit, isSubmitting }) => (
							<form className='row g-4' onSubmit={handleSubmit}>
								<div className='col-12'>
									<InputField
										label='Your Email'
										name='username'
										placeholder='ali@email.com'
									/>
								</div>
								<div className='text-danger'>{errorMessage}</div>

								<div className='col-12'>
									<PrimaryButton
										className='w-100 py-3 mb-3'
										isLoading={isSubmitting}
										label='Submit'
										type='submit'
									/>
									<Link className='text-center' to='/'>
										<p>Back</p>
									</Link>
								</div>
							</form>
						)}
					</Formik>
				</AuthCard>
			</Page>
		</PageWrapper>
	);
};

export default ForgotPasswordPage;
