import React, { useLayoutEffect, forwardRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const PageWrapper = forwardRef(
	({ children, className, description, title }, ref) => {
		useLayoutEffect(() => {
			document.getElementsByTagName('TITLE')[0].text = `${
				title ? `${title} | ` : ''
			}${
				process.env.REACT_APP_SITE_NAME
					? process.env.REACT_APP_SITE_NAME
					: 'Mirath Admin Portal'
			}`;
			document
				.querySelector('meta[name="description"]')
				.setAttribute(
					'content',
					description || process.env.REACT_APP_META_DESC,
				);
		});

		return (
			<div
				className={classNames('page-wrapper', 'container-fluid', className)}
				ref={ref}
			>
				{children}
			</div>
		);
	},
);
PageWrapper.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	title: null,
	description: null,
	className: null,
};

export default PageWrapper;
