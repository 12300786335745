import React from 'react';

import { superAdminMenu } from 'menu';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import DashboardHeader from 'container/common/Headers/DashboardHeader';
// import DefaultHeader from 'container/common/Headers/DefaultHeader';

const HeaderRoutes = () => {
	const location = useLocation();
	const { isLoggedIn } = useSelector((s) => s.auth);

	//	Add paths to the array that you don't want to be "Header".
	// const withoutHeaderPages = ['/login'];

	return (
		<Switch location={location}>
			{/* BEGIN :: Custom Headers */}
			{isLoggedIn && (
				<Route
					exact
					component={DashboardHeader}
					path={[
						superAdminMenu.dashboard.path,
						superAdminMenu.users.subMenu.user.path,
						superAdminMenu.users.subMenu.admin.path,
						superAdminMenu.mjk.subMenu.mjkMasjid.path,
						`${superAdminMenu.mjk.subMenu.mjkMasjid.path}/:id`,
					]}
				/>
			)}
			{/* END :: Custom Headers */}

			{/* BEGIN :: Without Header */}
			{!isLoggedIn && <Route exact />}
			{/* End :: Without Header */}

			<Route component={DashboardHeader} />
		</Switch>
	);
};

export default HeaderRoutes;
