import React from 'react';

import PropTypes from 'prop-types';

const Logo = ({ height, width }) => {
	return (
		<svg
			fill='none'
			height={width !== 2755 ? width * (854 / 2155) : height}
			viewBox='-200 0 2755 854'
			width={height !== 854 ? height * (2755 / 854) : width}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g display='none' id='Alternative_Logo' />
			<g id='Fonts' />
			<g id='Colors' />
			<g id='Branding' />
			<g id='WhiteLogo' />
			<g id='ColorLogo'>
				<g>
					<g>
						<path
							d='M1327,577c0,76.2-58.8,139.4-176.4,189.5c-105.4,44.9-222.8,67.3-352.1,67.3c-98,0-180.3-8.3-246.9-24.8
				c-112.8-28.7-169.2-78.6-169.2-149.6c0-81.9,56.4-147.6,169.2-197.3c89.7-39.2,103.7-0.8,49.5,19.4
				c-104.7,38.9-140.3,104-140.3,164.8c0,38.8,44.9,67.9,134.6,87.5c64,14.4,131.7,21.6,203.2,21.6c121.1,0,228-20.3,320.8-60.8
				c86.2-37.5,129.4-76.7,129.4-117.6c0-20.5-15.3-56.8-45.7-109.1c-3.9-6.5-5.9-13.3-5.9-20.3c0-10.9,4.1-20.1,12.4-27.8
				c8.3-7.6,17.9-11.4,28.7-11.4c13.9,0,24.8,6.8,32.7,20.3C1308.3,492.7,1327,542.2,1327,577z'
							fill='#5D94A8'
						/>
						<path
							d='M1489.4,671.1c0.9,9.6,1.3,18.9,1.3,28.1c0,57.9-9.4,86.9-28.1,86.9c-13.9,0-25.5-22.4-34.6-67.3
				c-4.8-23.9-12.2-79.1-22.2-165.3c-6.5-56.6-13.9-128-22.2-214.3v-4.6c0-19.6,5-36.1,15-49.7c9.6-13.1,20.5-19.6,32.7-19.6
				c14.8,0,23.1,9.8,24.8,29.4C1464.3,382.8,1475.4,508.2,1489.4,671.1z'
							fill='#5D94A8'
						/>
						<path
							d='M2080.5,951.6c-27.9,12-61.5,17.9-100.7,17.9c-35.6,0-53.3-7.5-53.3-22.4c0-10.6,15.1-18.3,45.4-22.9
				c20.6-3,49-12.1,85.3-27.4c4.3-2,8.6-3,13-3c19.3,0,28.9,10.5,28.9,31.4C2098.9,937.8,2092.8,946.6,2080.5,951.6z'
							fill='#A9CFA9'
						/>
						<path
							d='M2348.1,570.4c-8.3-0.9-15.9-1.3-22.9-1.3h-6.5c-20.5,0.9-34.8,8.9-43.1,24.2
				c-13.1,24.4-19.6,48.1-19.6,71.2c0,7.8,0.7,15.5,2,22.9c-38.8,22.2-91,41.4-156.8,57.5c-57.5,13.9-102.8,20.9-135.9,20.9
				c-6.3,0-11.8,0.8-16.6,2.3c-16-2.5-23.9-5.9-23.9-10.1c-6.5,0-12-14.1-16.3-42.5c0-38.3-14.6-57.5-43.8-57.5
				c-32.2,0-71,8.1-116.3,24.2c-76.2,27-132.8,77.1-169.9,150.3c-12.6,29.6-24.8,46.8-36.6,51.6c-24.4,14.8-74.9,30.3-151.6,46.4
				c-56.2,5.7-84.3,17-84.3,34v7.8c3.1,12.2,14.8,18.3,35.3,18.3h9.8c88,0,155.7-8.7,203.2-26.1c33.5-14.8,62.1-43.3,85.6-85.6
				c13.5-33.5,38.3-64.5,74.5-92.8c28.3-21.3,68.6-37.2,120.9-47.7c2.6,15.7,6.7,30.5,12.4,44.4c19.2,42.2,59.9,63.4,122.2,63.4
				c1.6,0,2.4-0.7,2.5-2.1c55.4-1.1,128.1-15.6,218.3-43.3c94.1-29,162.2-58.2,204.5-87.9c27.9-19.6,43.1-44.9,45.7-75.8v-4.6
				C2440.8,598.1,2409.9,577.4,2348.1,570.4z'
							fill='#5D94A8'
						/>
						<g>
							<g>
								<path
									d='M963.7,367.8c-39.3,36.9-77.3,41-77.1,38.3c1.8-40.5,23.3-59.1,46.8-81.4c23.6-22.4,61.6-40.3,76.1-46.7
						c2.4-1.1,4.9,1.2,4.2,3.7C1009.2,297.2,995.1,338.4,963.7,367.8z'
									fill='#EFA5A5'
								/>
								<path
									d='M814.1,484.6c-12.8-25.9-13.6-63-14.7-93.1c-1.4-40.1,10.1-90.7,14.8-109.3c0.7-2.7,4.4-3.2,5.7-0.7
						c8.8,16.5,30.3,61.6,32,114.2c1.2,39.2-5.1,72.6-9.1,89.5c-0.7,3,2.9,5,5.1,2.9c0,0,0,0,0.1-0.1l0.8-0.8
						c13.2-16.4,27.7-29.4,70.2-55.5c53.4-32.8,109.6-43.8,130.9-47.1c2.9-0.4,4.8,3.1,2.6,5.2c-16.3,16.1-61.2,59.1-102.3,84.2
						c-35.3,21.5-66.4,39-105.6,41.1c-9.1,0.5-17.8-4.2-22.2-12.1C821.5,501.3,818.8,494.1,814.1,484.6z'
									fill='#EFA5A5'
								/>
							</g>
						</g>
					</g>
					<g>
						<path
							d='M863.2,1349.3l-85.5-158.7v161.2c0,3-0.8,6.3-7.9,6.3c-6.5,0-7.3-3.3-7.3-6.3v-188.7c0-6,2.7-9.3,7.1-9.3
				c4.4,0,6.5,1.9,7.9,4.4l94.2,176.4l100.2-176.9c1.6-3,3.5-3.8,7.3-3.8c4.1,0,6,3.3,6,8.4v189.5c0,3.3-0.8,6.3-7.4,6.3
				c-7.1,0-7.9-3-7.9-6.3v-162l-90.7,159.5c-2.2,3.5-4.4,6-8.2,6C867.3,1355.3,864.8,1352.6,863.2,1349.3z'
							fill='#5D94A8'
							stroke='#5D94A8'
							strokeMiterlimit='10'
							strokeWidth='6.3183'
						/>
						<path
							d='M1108,1351.7c0,0,0,6.3-8.2,6.3c-8.2,0-8.2-6.3-8.2-6.3v-191.6c0,0,0-6.3,8.2-6.3c8.2,0,8.2,6.3,8.2,6.3
				V1351.7z'
							fill='#5D94A8'
							stroke='#5D94A8'
							strokeMiterlimit='10'
							strokeWidth='6.3183'
						/>
						<path
							d='M1361.4,1340.9c2.2,0,3.5,2.7,3.5,5.2c0,7.4-9.3,12-19.9,12c-24.8,0-41.1-52.5-71.9-70
				c-8.7,1.6-17.7,2.2-26.7,2.2c-7.4,0-9.5-2.7-9.5-7.1c0-6.3,2.2-7.4,6-7.4c45.7,0,90.1-18,90.1-58.8c0-39.7-31-48.2-80.6-48.2
				h-21.8v182.9c0,0,0,6.3-8.2,6.3c-8.2,0-8.2-6.3-8.2-6.3v-192.5c0-3.3,2.2-5.4,5.4-5.4h32.7c58.8,0,96.9,14.2,96.9,63.7
				c0,33.8-24.5,55.8-56.6,65.9c27,20.1,41.4,58.3,58.5,58.3C1358.7,1341.7,1359.2,1340.9,1361.4,1340.9z'
							fill='#5D94A8'
							stroke='#5D94A8'
							strokeMiterlimit='10'
							strokeWidth='6.3183'
						/>
						<path
							d='M1467.1,1312.3l-18.2,40.6c-1.4,2.7-2.7,5.2-9.5,5.2c-4.9,0-7.1-3-7.1-6.3c0-1.1,0.8-2.7,0.8-2.7
				l86.8-188.9c2.5-5.2,4.1-7.9,10.6-7.9c6,0,8.7,3.5,10.3,7.4l87.1,189.5c0,0,0.8,2.2,0.8,3.8s0,5.2-7.1,5.2
				c-5.7,0-8.4-2.7-9.5-5.2l-18.2-40.6H1467.1z M1473.9,1297.3h113.2l-56.6-125.5L1473.9,1297.3z'
							fill='#5D94A8'
							stroke='#5D94A8'
							strokeMiterlimit='10'
							strokeWidth='6.3183'
						/>
						<path
							d='M1753.3,1168.8v182.9c0,0,0,6.3-8.2,6.3c-8.2,0-8.2-6.3-8.2-6.3v-182.9h-61.8c0,0-6.3,0-6.3-7.3
				c0-7.6,6.3-7.6,6.3-7.6h139.9c0,0,6.3,0,6.3,7.6c0,7.3-6.3,7.3-6.3,7.3H1753.3z'
							fill='#5D94A8'
							stroke='#5D94A8'
							strokeMiterlimit='10'
							strokeWidth='6.3183'
						/>
						<path
							d='M1911,1283.7v68.1c0,0,0,6.3-8.2,6.3c-8.2,0-8.2-6.3-8.2-6.3v-191.6c0,0,0-6.3,8.2-6.3
				c8.2,0,8.2,6.3,8.2,6.3v108.6h133.4v-108.6c0,0,0-6.3,8.2-6.3c8.2,0,8.2,6.3,8.2,6.3v191.6c0,0,0,6.3-8.2,6.3
				c-8.2,0-8.2-6.3-8.2-6.3v-68.1H1911z'
							fill='#5D94A8'
							stroke='#5D94A8'
							strokeMiterlimit='10'
							strokeWidth='6.3183'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
Logo.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
};
Logo.defaultProps = {
	height: 854,
	width: 2155,
};

export default Logo;
