import React, { useState } from 'react';

import { Formik } from 'formik';
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { Link, useHistory, Redirect } from 'react-router-dom';
import * as Yup from 'yup';

import Page from 'layout/Page/Page';
import PageWrapper from 'layout/PageWrapper/PageWrapper';

import Checks, { ChecksGroup } from 'components/bootstrap/forms/Checks';
import AuthCard from 'components/card/AuthCard';
import { PrimaryButton } from 'components/CommonButton';
import InputField from 'components/form/InputField';

import { resetPassword } from 'redux/actions/auth';

const ResetPasswordPage = () => {
	const [errorMessage, setErrorMessage] = useState(null);
	const [isPasswordHidden, setPasswordHidden] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleChange = () => {
		setPasswordHidden(!isPasswordHidden);
		// console.log(isPasswordHidden);
	};

	// check history data
	const { code, username } = qs.parse(history.location.search, {
		decode: false,
	});
	const user = String(username);

	const validate = Yup.object({
		username: Yup.string().email('Invalid Email').required('Required'),
		confirmation_code: Yup.string()
			.min(6, 'Must be 6 characters long')
			.max(6, 'Must be 6 characters long')
			.matches(/^[\d]{6,}$/, 'Invalid Code')
			.required('Required'),
		password: Yup.string()
			.min(10, 'Password too short')
			.max(30, 'Password too long')
			.matches(/^[\S]{10,}$/, 'Invalid Password')
			.required('Required'),
	});

	// if history data invalid Redirect
	if (username === undefined) return <Redirect to='/' />;

	return (
		<PageWrapper className='bg-primary' title='Reset Password'>
			<Page className='p-0'>
				<AuthCard
					mainText='Reset Password'
					subText='Enter your code and new password!'
				>
					<Formik
						initialValues={{
							username: user,
							confirmation_code: code || '',
							password: '',
						}}
						validationSchema={validate}
						onSubmit={(formValues) => {
							setErrorMessage(null);
							return dispatch(resetPassword(formValues)).then(
								({ error, success }) => {
									if (success) {
										history.push('/');
									}
									if (!success) {
										setErrorMessage(error);
									}
								},
							);
						}}
					>
						{({ handleSubmit, isSubmitting }) => (
							<form className='row g-4' onSubmit={handleSubmit}>
								<div className='col-12'>
									<InputField
										disabled
										label='Email'
										name='username'
										placeholder='ali@email.com'
									/>
									<InputField
										label='Temporary Code'
										name='confirmation_code'
										placeholder='123456'
									/>
									<InputField
										label='Your Password'
										name='password'
										placeholder='**********'
										type={isPasswordHidden ? 'text' : 'password'}
									/>
								</div>
								<ChecksGroup className='pb-1'>
									<Checks
										checked={isPasswordHidden}
										label='Show Password'
										type='switch'
										value=''
										onChange={handleChange}
									/>
								</ChecksGroup>
								<div className='text-danger'>{errorMessage}</div>

								<div className='col-12'>
									<PrimaryButton
										className='w-100 py-3 mb-3'
										isLoading={isSubmitting}
										label='Submit'
										type='submit'
									/>
									<Link className='text-center' to='/'>
										<p>Back</p>
									</Link>
								</div>
							</form>
						)}
					</Formik>
				</AuthCard>
			</Page>
		</PageWrapper>
	);
};

export default ResetPasswordPage;
