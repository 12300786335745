import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
	QueryParamProvider,
	transformSearchStringJsonSafe,
} from 'use-query-params';

import store from 'redux/store';

import './styles/styles.scss';
import App from './App/App';
import { ThemeContextProvider } from './contexts/themeContext';
import reportWebVitals from './reportWebVitals';
import './i18n';

const queryStringifyOptions = {
	transformSearchString: transformSearchStringJsonSafe,
};

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<QueryParamProvider
				ReactRouterRoute={Route}
				stringifyOptions={queryStringifyOptions}
			>
				<ThemeContextProvider>
					<Provider store={store}>
						<App />
					</Provider>
				</ThemeContextProvider>
			</QueryParamProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
