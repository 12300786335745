import React, { useContext, useState } from 'react';

// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';

import { HeaderRight } from 'layout/Header/Header';

import Alert from 'components/bootstrap/Alert';
import Button from 'components/bootstrap/Button';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from 'components/bootstrap/OffCanvas';

import ThemeContext from 'contexts/themeContext';

import useDarkMode from 'hooks/useDarkMode';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ afterChildren, beforeChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	// const { i18n } = useTranslation();

	// const changeLanguage = (lng) => {
	// 	i18n.changeLanguage(lng);
	// 	showNotification(
	// 		<span className='d-flex align-items-center'>
	// 			<Icon className='me-1' icon={getLangWithKey(lng)?.icon} size='lg' />
	// 			<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
	// 		</span>,
	// 		'You updated the language of the site. (Only "Aside" was prepared as an example.)',
	// 	);
	// };

	/**
	 * Language attribute
	 */
	// useLayoutEffect(() => {
	// 	document.documentElement.setAttribute('lang', i18n.language);
	// });

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}
				{/* Dark Mode */}
				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						aria-label='Toggle fullscreen'
						data-tour='dark-mode'
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
					/>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						aria-label='Toggle dark mode'
						icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
						onClick={() => setFullScreenStatus(!fullScreenStatus)}
					/>
				</div>

				{/* Lang Selector */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								aria-label='Change language'
								data-tour='lang-selector'
								icon={getLangWithKey(i18n.language)?.icon}
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}
									>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{/*	Notifications */}
				{/* <div className='col-auto'> */}
				{/*	<Button */}
				{/*		// eslint-disable-next-line react/jsx-props-no-spreading */}
				{/*		{...styledBtn} */}
				{/*		icon='Notifications' */}
				{/*		onClick={() => setOffcanvasStatus(true)} */}
				{/*		aria-label='Notifications' */}
				{/*	/> */}
				{/* </div> */}
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				isOpen={offcanvasStatus}
				placement='end'
				setOpen={setOffcanvasStatus}
				titleId='offcanvasExampleLabel'
			>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>
						Notifications
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Alert isLight className='flex-nowrap' color='info' icon='ViewInAr'>
						4 new components added.
					</Alert>
					<Alert isLight className='flex-nowrap' color='warning' icon='ThumbUp'>
						New products added to stock.
					</Alert>
					<Alert
						isLight
						className='flex-nowrap'
						color='danger'
						icon='Inventory2'
					>
						There are products that need to be packaged.
					</Alert>
					<Alert
						isLight
						className='flex-nowrap'
						color='success'
						icon='BakeryDining'
					>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert
						isLight
						className='flex-nowrap'
						color='primary'
						icon='Escalator'
					>
						Escalator will turn off at 6:00 pm.
					</Alert>
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	afterChildren: PropTypes.node,
	beforeChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	afterChildren: null,
	beforeChildren: null,
};

export default CommonHeaderRight;
