import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';

const Brand = ({ asideStatus, setAsideStatus }) => {
	return (
		<div className='brand'>
			<div className='brand-logo ps-4'>
				<h1 className='brand-title '>
					<Link alt='Facit' aria-label='Logo' to='/'>
						<Logo height={32} />
					</Link>
				</h1>
			</div>
			<div className='pt-5'>
				<button
					aria-label='Toggle Aside'
					className='btn brand-aside-toggle'
					type='button'
					onClick={() => setAsideStatus(!asideStatus)}
				>
					<Icon className='brand-aside-toggle-close' icon='FirstPage' />
					<Icon className='brand-aside-toggle-open' icon='LastPage' />
				</button>
			</div>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
