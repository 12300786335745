import React, { useState } from 'react';

import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Page from 'layout/Page/Page';
import PageWrapper from 'layout/PageWrapper/PageWrapper';

import Checks, { ChecksGroup } from 'components/bootstrap/forms/Checks';
import AuthCard from 'components/card/AuthCard';
import { PrimaryButton } from 'components/CommonButton';
import { showErrorNotification } from 'components/extras/showNotification';
import InputField from 'components/form/InputField';

import { userLogin } from 'redux/actions/auth';

const LoginPage = () => {
	const [isPasswordHidden, setPasswordHidden] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleChange = () => {
		setPasswordHidden(!isPasswordHidden);
	};

	const validate = Yup.object({
		username: Yup.string().email('Invalid Email').required('Required'),
		password: Yup.string()
			.min(9, 'Password must be 10 characters')
			.max(30, 'Password must be 30 characters or below')
			.matches(/^[\S]{9,}$/, 'Invalid Password')
			.required('Required'),
	});

	return (
		<PageWrapper className='bg-primary' title='Sign In'>
			<Page className='p-0'>
				<AuthCard mainText='Welcome,' subText='Sign in to continue'>
					<Formik
						initialValues={{
							username: '',
							password: '',
						}}
						// innerRef={formRef}
						validationSchema={validate}
						onSubmit={(formValues) => {
							return dispatch(userLogin(formValues))
								.then((response) => {
									if (response.success) {
										// console.log('success', formValues);
										// Checks if user first time login go to new password
										if (response.first_login)
											history.push({
												pathname: '/new-password',
												search: `?username=${formValues.username}`,
												state: {
													from: history.location.pathname,
													allow: true,
													session: response.session,
												},
											});
										// If not first time login go to authenticated homepage
										if (!response.first_login) history.push('/');
									}
									if (!response.success) {
										// console.log(response);
										showErrorNotification('Error', response.error, 'Error');
									}
								})
								.catch((error) =>
									showErrorNotification('Error', error.message, 'Error'),
								);
						}}
					>
						{({ handleSubmit, isSubmitting }) => (
							<form className='row g-4' onSubmit={handleSubmit}>
								<div className='col-12'>
									<InputField
										label='Your Email'
										name='username'
										placeholder='ali@email.com'
									/>
									<InputField
										label='Your Password'
										name='password'
										placeholder='**********'
										type={isPasswordHidden ? 'text' : 'password'}
									/>
								</div>
								<ChecksGroup className='pb-1'>
									<Checks
										checked={isPasswordHidden}
										label='Show Password'
										type='switch'
										value=''
										onChange={handleChange}
									/>
								</ChecksGroup>

								<div className='col-12'>
									<PrimaryButton
										className='w-100 py-3 mb-3'
										isLoading={isSubmitting}
										label='Sign In'
										type='submit'
									/>
									<Link className='text-center' to='/forgot-password'>
										<p>Forgot Password</p>
									</Link>
								</div>
							</form>
						)}
					</Formik>
				</AuthCard>
			</Page>
		</PageWrapper>
	);
};

export default LoginPage;
