import React from 'react';

import DefaultImage from 'assets/img/MirathLogo.svg';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Image = ({
	alt = 'an image',
	className,
	isThumbnail,
	src,
	style,
	...props
}) => (
	<img
		alt={alt}
		className={cx(isThumbnail ? 'img-thumbnail' : 'img-fluid', className)}
		src={src || DefaultImage}
		style={{ objectFit: 'contain', ...style }}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	/>
);

Image.propTypes = {
	alt: PropTypes.string.isRequired,
	className: PropTypes.string,
	isThumbnail: PropTypes.bool,
	src: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
};

Image.defaultProps = {
	className: '',
	isThumbnail: false,
	src: DefaultImage,
	style: null,
};

export default Image;
