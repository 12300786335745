import { createActions } from 'redux-actions';

import api from 'common/api';

import { LOGIN_SUCCESS, SIGN_OUT } from '../constants';

const { loginSuccess, signOut } = createActions(
	{
		[LOGIN_SUCCESS]: ({ data }) => {
			// eslint-disable-next-line camelcase
			const { access_token, refresh_token, user } = data;
			localStorage.setItem('refresh_token', refresh_token);
			localStorage.setItem('access_token', access_token);
			return {
				user,
				access_token,
				refresh_token,
			};
		},
	},
	SIGN_OUT,
);

const checkHasUser = () => (dispatch) => {
	// eslint-disable-next-line camelcase
	const access_token = localStorage.getItem('access_token');
	// eslint-disable-next-line camelcase
	const refresh_token = localStorage.getItem('refresh_token');
	// eslint-disable-next-line camelcase
	if (refresh_token && access_token) {
		return api('/profile', {
			headers: {
				// eslint-disable-next-line camelcase
				Authorization: `Bearer ${access_token}`,
			},
			params: { refresh_token },
		})
			.then(({ data }) => {
				dispatch(
					loginSuccess({
						data: {
							access_token: data.data.tokens.AccessToken,
							refresh_token,
							user: data.data.user,
						},
					}),
				);
				return Promise.resolve(true);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.log('Invalid!', error.message);
				localStorage.removeItem('access_token');
				localStorage.removeItem('refresh_token');
				return Promise.resolve(false);
			});
	}
	return Promise.resolve(false);
};

const submitLogout = () => (dispatch) => {
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('access_token');
	dispatch(signOut());
};

// LOGIN
const userLogin = (values) => {
	return (dispatch) => {
		return (
			api
				.post(`auth/signin`, values)
				// eslint-disable-next-line consistent-return
				.then((res) => {
					// console.log(res);
					if (!res.data.error) {
						// to catch when user login but not changed their password
						if (res.data.data.ChallengeName)
							return {
								success: true,
								first_login: true,
								session: res.data.data.Session,
							};

						dispatch(
							loginSuccess({
								data: {
									access_token: res.data.data.AccessToken,
									refresh_token: res.data.data.RefreshToken,
									user: res.data.data.user,
								},
							}),
						);
						return { success: true };
					}
					if (res.data.error) {
						// console.log('eeror data: ', res.data.error)
						return { success: false, error: res.data.message };
					}
				})
				.catch((err) => {
					return { success: false, error: err };
				})
		);
	};
};

// FORGOT PASSWORD
export const forgotPasswordCheck = (values) => {
	return (dispatch) => {
		return api
			.post(`auth/forgot-password`, values)
			.then((res) => {
				if (res.data?.data?.need_complete_signup) {
					return { success: true, needCompleteSignup: true };
				}
				if (!res.data.error) {
					// console.log('eeror data1: ', res.data.message)
					// console.log('message data: ', res.data.message)
					dispatch(submitLogout());
					return { success: true };
				}
				if (res.data.error) {
					// console.log(res);
					// console.log('eeror data2: ', res.data.message)
					return { success: false, error: res.data.message };
				}
				return { success: true };
			})
			.catch((err) => {
				return { success: false, error: err };
			});
	};
};

// RESET PASSWORD
export const resetPassword = (values) => {
	return (dispatch) => {
		return api
			.post(`auth/forgot-password/confirm`, values)
			.then((res) => {
				if (!res.data.error) {
					// console.log('eeror data1: ', res.data.message)
					// console.log('message data: ', res.data.message)

					dispatch(
						loginSuccess({
							data: {
								access_token: res.data.data.AccessToken,
								refresh_token: res.data.data.RefreshToken,
								user: res.data.data.user,
							},
						}),
					);

					return { success: true };
				}
				if (res.data.error) {
					// console.log(res)
					// console.log('eeror data2: ', res.data.message)
					return { success: false, error: res.data.message };
				}
				return { success: true };
			})
			.catch((err) => {
				// console.log('the error catched: ', err)
				return { success: false, error: err };
			});
	};
};

// CHANGE PASSSWORD - FIRST LOGIN
export const changePassword = (values) => {
	return (dispatch) => {
		return api
			.post(`auth/force-change-password`, values)
			.then((res) => {
				if (!res.data.error) {
					dispatch(
						loginSuccess({
							data: {
								access_token: res.data.data.AccessToken,
								refresh_token: res.data.data.RefreshToken,
								user: res.data.data.user,
							},
						}),
					);
					return { success: true };
				}
				if (res.data.error) {
					return { success: false, error: res.data.message };
				}
				return { success: true };
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log('the error: ', err);
				return false;
			});
	};
};

export { checkHasUser, submitLogout, userLogin };
