import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';

import FormGroup from 'components/bootstrap/forms/FormGroup';
import Input from 'components/bootstrap/forms/Input';

// Used in the Formik Field component
const FormikInputComponent = ({
	field, // { name, value, onChange, onBlur }
	form: { errors, isValid, touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => {
	return (
		<>
			<Input
				invalidFeedback={errors[field.name]}
				isTouched={touched[field.name]}
				isValid={isValid}
				validFeedback='Looks Good!'
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...field}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
		</>
	);
};

const InputField = ({
	autoComplete,
	className,
	disabled,
	isFloating,
	label,
	mask,
	max,
	min,
	name,
	placeholder,
	type,
}) => {
	return (
		// if isFloating true, the placeholder is hidden
		<FormGroup className={className} isFloating={isFloating} label={label}>
			<Field
				autoComplete={autoComplete}
				component={FormikInputComponent}
				disabled={disabled}
				mask={mask}
				max={max}
				min={min}
				name={name}
				placeholder={placeholder}
				type={type}
			/>
		</FormGroup>
	);
};

FormikInputComponent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	field: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	form: PropTypes.object.isRequired,
};

InputField.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	isFloating: PropTypes.bool,
	mask: PropTypes.string,
	max: PropTypes.number,
	min: PropTypes.number,
	placeholder: PropTypes.string,
	type: PropTypes.string,
};

InputField.defaultProps = {
	autoComplete: null,
	className: '',
	disabled: false,
	isFloating: true,
	mask: '',
	max: 100,
	min: 0,
	placeholder: 'Default Placeholder',
	type: 'text',
};

export default InputField;
