import React from 'react';

import Header, { HeaderLeft } from 'layout/Header/Header';

import CommonHeaderRight from './CommonHeaderRight';
import HeaderAvatar from './HeaderAvatar';

const DashboardHeader = () => {
	return (
		<Header>
			<HeaderLeft>
				<HeaderAvatar />
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DashboardHeader;
