import React from 'react';

import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import Footer from './Footer';

const FooterRoutes = () => {
	const location = useLocation();
	const { isLoggedIn } = useSelector((s) => s.auth);

	//	Add paths to the array that you don't want to be "Footer".
	// const withoutFooterPages = [superAdminMenu.login.path];

	return (
		<Switch location={location}>
			{/* BEGIN :: Without Footer */}
			{!isLoggedIn && <Route exact />}
			{/* END :: Without Footer */}

			<Route component={Footer} />
		</Switch>
	);
};

export default FooterRoutes;
