const serviceMenu = {
	services: {
		id: 'services',
		text: 'Services',
		icon: 'Extension',
	},
	// MJK menu
	mjk: {
		id: 'mjk',
		text: 'MJK',
		path: '/mjk',
		icon: 'bag',
		subMenu: {
			mjkHome: {
				id: 'mjkHome',
				text: 'Dashboard',
				path: '/mjk/dashboard',
				icon: 'Dashboard',
			},
			mjkMasjid: {
				id: 'mjkMasjid',
				text: 'Masjid',
				path: '/mjk/masjid',
				icon: 'MoonStarsFill',
			},
			mjkOrder: {
				id: 'mjkOrder',
				text: 'Orders',
				path: '/mjk/orders',
				icon: 'CardList',
			},
			mjkKit: {
				id: 'mjkKit',
				text: 'Kit',
				path: '/mjk/kit',
				icon: 'bag',
			},
			mjkVerify: {
				id: 'mjkVerify',
				text: 'Verify',
				path: '/mjk/verify',
				icon: 'VpnKey',
			},
			mjkContact: {
				id: 'mjkContact',
				text: 'Contact',
				path: '/mjk/contact',
				icon: 'ChatLeftDots',
			},
		},
	},

	// Faraid menu
	faraid: {
		id: 'faraid',
		text: 'Faraid',
		path: '/faraid',
		icon: 'calculator',
		subMenu: {
			faraidHome: {
				id: 'faraidHome',
				text: 'Dashboard',
				path: '/faraid',
				icon: 'Dashboard',
			},
			faraidUser: {
				id: 'faraidUser',
				text: 'Users',
				path: '/faraid/users',
				icon: 'FilePerson',
			},
			faraidSimplePlans: {
				id: 'faraidSimplePlans',
				text: 'Simple Plans',
				path: '/faraid/simple-plan',
				icon: 'CardList',
			},
		},
	},

	// Wasiat menu TODO: disabled
	// wasiat: {
	// 	id: 'wasiat',
	// 	text: 'Wasiat',
	// 	path: '/wasiat',
	// 	icon: 'FileTextFill',
	// 	subMenu: {
	// 		mjkHome: {
	// 			id: 'wasiatHome',
	// 			text: 'Dashboard',
	// 			path: '/wasiat',
	// 			icon: 'Dashboard',
	// 		},
	// 	},
	// },

	// Hibah Menu TODO: disabled
	// hibah: {
	// 	id: 'hibah',
	// 	text: 'Hibah',
	// 	path: '/hibah',
	// 	icon: 'Gift',
	// 	subMenu: {
	// 		mjkHome: {
	// 			id: 'hibahHome',
	// 			text: 'Dashboard',
	// 			path: '/hibah',
	// 			icon: 'Dashboard',
	// 		},
	// 	},
	// },
};

// TODO I think there might be a way to reduce duplication
export const superAdminMenu = {
	// Home
	dashboard: {
		id: 'home',
		text: 'Home',
		path: '/',
		icon: 'House',
	},
	users: {
		id: 'administrators',
		text: 'Administrators',
		path: '/administrator-management',
		icon: 'SupervisedUserCircle',
		subMenu: {
			admin: {
				id: 'superadmin',
				text: 'Super Admin',
				path: '/administrator-management/super-admin?page=1&limit=10&order=id&search=',
				icon: 'PersonBadge',
			},
			user: {
				id: 'normaladmin',
				text: 'Normal Admin',
				path: '/administrator-management/normal-admin?page=1&limit=10&order=id&search=',
				icon: 'FilePerson',
			},
		},
	},
	...serviceMenu,
};

export const normalAdminMenu = {
	// Home
	dashboard: {
		id: 'home',
		text: 'Home',
		path: '/',
		icon: 'House',
	},
	...serviceMenu,
};
