import React from 'react';

import PropTypes from 'prop-types';

import Button from 'components/bootstrap/Button';
import Spinner from 'components/bootstrap/Spinner';

const CommonButton = ({
	children,
	className,
	color,
	icon,
	isDisable,
	isLight,
	isLoading,
	label,
	onClick,
	size,
	type,
	...props
}) => {
	return (
		<Button
			className={className}
			color={color}
			icon={isLoading ? '' : icon}
			isDisable={isDisable}
			isLight={isLight}
			size={size}
			type={type}
			onClick={onClick}
			{...props}
		>
			{isLoading ? <Spinner size='2vh' /> : children || label}
		</Button>
	);
};

CommonButton.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.array,
		PropTypes.node,
	]),
	className: PropTypes.string,
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
	]),
	icon: PropTypes.string,
	isDisable: PropTypes.bool,
	isLight: PropTypes.bool,
	isLoading: PropTypes.bool,
	size: PropTypes.oneOf(['sm', null, 'lg']),
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
	onClick: PropTypes.func,
};

CommonButton.defaultProps = {
	children: null,
	className: '',
	color: 'primary',
	icon: '',
	isDisable: false,
	isLight: false,
	isLoading: false,
	onClick: () => {},
	size: null,
	type: 'button',
};

export const PrimaryButton = (props) => (
	<CommonButton color='primary' {...props} />
);

export const SecondaryButton = (props) => (
	<CommonButton color='secondary' {...props} />
);

export const SaveButton = (props) => (
	<CommonButton color='info' icon='Save' label='Save' {...props} />
);

export const EditButton = ({ ...props }) => (
	<CommonButton color='warning' icon='Edit' label='Edit' {...props} />
);

export const AddButton = (props) => (
	<CommonButton color='primary' icon='Add' label='Add New' {...props} />
);

// TODO: There is a prop bug
export const FilterButton = (props) => (
	<CommonButton color='info' icon='FilterList' label='Filter' {...props} />
);

export const ExportButton = (props) => (
	<CommonButton
		color='secondary'
		icon='ImportExport'
		label='Export'
		{...props}
	/>
);

export const DeleteButton = ({ ...props }) => (
	<CommonButton color='danger' icon='Delete' label='Delete' {...props} />
);

export default CommonButton;
