import React from 'react';

import classNames from 'classnames';
import { useFormik } from 'formik';
import { Link, useLocation } from 'react-router-dom';

import Page from 'layout/Page/Page';
import PageWrapper from 'layout/PageWrapper/PageWrapper';

import Button from 'components/bootstrap/Button';
import Card, { CardBody } from 'components/bootstrap/Card';
import FormGroup from 'components/bootstrap/forms/FormGroup';
import Input from 'components/bootstrap/forms/Input';
import Logo from 'components/Logo';

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const AcceptInvitePage = () => {
	const query = useQuery();
	const formik = useFormik({
		initialValues: {
			inviteUsername: query.get('username'),
		},
		onSubmit: (values) => {
			// TODO: login here
			// eslint-disable-next-line no-console
			console.log(values);
		},
	});

	return (
		<PageWrapper className='bg-warning' title='Accept Invite'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										className={classNames(
											'text-decoration-none fw-bold display-2 text-light',
										)}
										to='/'
									>
										<Logo width={200} />
									</Link>
								</div>

								<>
									<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
									<div className='text-center h4 text-muted mb-5'>
										You have accepted an invite!
									</div>
								</>

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<FormGroup isFloating label='Your email or username'>
												<Input
													id='inviteUsername'
													value={formik.values.inviteUsername}
													onChange={formik.handleChange}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												className='w-100 py-3'
												color='warning'
												onClick={formik.handleSubmit}
											>
												Accept Invite
											</Button>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								className={classNames('text-decoration-none me-3', 'link-dark')}
								href='/'
							>
								Privacy policy
							</a>
							<a
								className={classNames('link-dark text-decoration-none')}
								href='/'
							>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AcceptInvitePage;
