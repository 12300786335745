import React, { useContext, useState, useEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../components/bootstrap/Spinner';
import ThemeContext from '../../contexts/themeContext';
import Content from '../Content/Content';
import FooterRoutes from '../Footer/FooterRoutes';
import HeaderRoutes from '../Header/HeaderRoutes';
import WrapperOverlay from './WrapperOverlay';

export const WrapperContainer = ({ children, className, ...props }) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames(
				'wrapper',
				{ 'wrapper-right-panel-active': rightPanel },
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			{children}
		</div>
	);
};
WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
WrapperContainer.defaultProps = {
	className: null,
};

const Wrapper = () => {
	// check if user has logged in
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 1500);
	}, []);

	if (isLoading) {
		return (
			<div className='vh-100 vw-100 d-flex align-items-center justify-content-center'>
				<Spinner color='primary' size='5vh' />
			</div>
		);
	}

	return (
		<>
			<WrapperContainer>
				<HeaderRoutes />
				<Content />
				<FooterRoutes />
			</WrapperContainer>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
