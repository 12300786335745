import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'components/bootstrap/Dropdown';

import { submitLogout } from 'redux/actions/auth';

import useDarkMode from 'hooks/useDarkMode';

import USERS from 'common/data/userDummyData';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img alt='Avatar' height={128} src={src} srcSet={srcSet} width={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((s) => s.auth);
	const history = useHistory();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<UserAvatar src={USERS.JOHN.src} srcSet={USERS.JOHN.srcSet} />
					<div className='user-info'>
						<div className='user-name'>{user.display_name}</div>
						<div className='user-sub-title'>{user.role}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<Button
						aria-label='Toggle fullscreen'
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
					>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button
						icon='Logout'
						onClick={() => {
							dispatch(submitLogout());
							history.push('/');
						}}
					>
						Logout
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default User;
