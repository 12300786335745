import React, {
	useContext,
	forwardRef,
	useState,
	useRef,
	useCallback,
} from 'react';

import useEventOutside from '@omtanke/react-use-event-outside';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { NavLink, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { useWindowSize } from 'react-use';

import Collapse from '../../components/bootstrap/Collapse';
import Icon from '../../components/icon/Icon';
import ThemeContext from '../../contexts/themeContext';
import useDarkMode from '../../hooks/useDarkMode';

export const List = forwardRef(
	(
		{
			id,
			children,
			className,
			ariaLabelledby,
			parentId,
			rootId,
			horizontal,
			...props
		},
		ref,
	) => {
		return (
			<ul
				aria-labelledby={ariaLabelledby}
				className={classnames(
					'navigation',
					{ 'navigation-menu': horizontal },
					className,
				)}
				data-bs-parent={
					parentId === `${rootId}__${rootId}`
						? `#${rootId}`
						: (parentId && `#${parentId}`) || null
				}
				id={id}
				ref={ref}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
			</ul>
		);
	},
);
List.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	ariaLabelledby: PropTypes.string,
	parentId: PropTypes.string,
	rootId: PropTypes.string,
	horizontal: PropTypes.bool,
};
List.defaultProps = {
	id: null,
	children: null,
	className: null,
	ariaLabelledby: null,
	parentId: null,
	rootId: null,
	horizontal: false,
};

export const Item = ({
	children,
	to,
	title,
	icon,
	id,
	parentId,
	rootId,
	isHorizontal,
	notification,
	isMore,
	hide,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();
	const { width } = useWindowSize();
	const { setAsideStatus, setLeftMenuStatus, setRightMenuStatus } =
		useContext(ThemeContext);

	// eslint-disable-next-line react/prop-types
	const _active = props.activeItem === id;

	const handleClick = () => {
		// eslint-disable-next-line react/prop-types,no-unused-expressions
		_active ? props.setActiveItem(null) : props.setActiveItem(id);
	};

	const linkHandleClick = () => {
		// For Mobile Design
		if (width < process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
			setAsideStatus(false);
		setLeftMenuStatus(false);
		setRightMenuStatus(false);
	};

	const _anchorLinkPattern = /^#/i;
	const location = useLocation();

	// For aside menu
	const here = to !== '/' && location.pathname.includes(to);
	// For top menu
	const match = to !== '/' && location.pathname === to;

	const { t } = useTranslation('menu');

	const _LinkClass = classnames('navigation-link', 'navigation-link-pill', {
		collapsed: !!children && !isHorizontal,
		active: isHorizontal ? match : here,
	});

	const _Inner = (
		<>
			<span className='navigation-link-info'>
				{icon && <Icon className='navigation-icon' icon={icon} />}
				<span className='navigation-text'>{t(title)}</span>
			</span>
			{(!!children || !!notification) && (
				<span className='navigation-link-extra'>
					{!!notification && (
						<Icon
							className={classnames(
								'navigation-notification',
								{
									[`text-${notification}`]: typeof notification === 'string',
									'text-danger': typeof notification !== 'string',
								},
								'animate__animated animate__heartBeat animate__infinite animate__slower',
							)}
							icon='Circle'
						/>
					)}
					{!!children && (
						<Icon className='navigation-arrow' icon='ChevronRight' />
					)}
				</span>
			)}
		</>
	);

	const _withoutChild =
		!children &&
		!hide &&
		((_anchorLinkPattern.test(to) && (
			<NavHashLink
				exact
				activeClassName='active'
				className={_LinkClass}
				to={to}
				onClick={linkHandleClick}
			>
				{_Inner}
			</NavHashLink>
		)) || (
			<NavLink
				exact
				activeClassName='active'
				className={_LinkClass}
				to={to}
				onClick={linkHandleClick}
			>
				{_Inner}
			</NavLink>
		));

	// Dropdown
	const dropdownRef = useRef(null);

	const dropdownButtonRef = useRef(null);
	const setButtonRef = useCallback((node, ref) => {
		dropdownButtonRef.current = node;
		return ref(node);
	}, []);

	const dropdownListRef = useRef(null);
	const setListRef = useCallback((node, ref) => {
		dropdownListRef.current = node;
		return ref(node);
	}, []);

	const [dropdownStatus, setDropdownStatus] = useState(false);

	const dropdownButtonHandleClick = () => {
		setDropdownStatus(!dropdownStatus);
	};

	// Clicking outside to close
	const closeMenu = useCallback(() => {
		setDropdownStatus(false);
	}, []);
	useEventOutside(dropdownRef, 'mousedown', closeMenu);
	useEventOutside(dropdownRef, 'touchstart', closeMenu);

	if (children) {
		// submenu && in header
		if (isHorizontal) {
			return (
				<Manager>
					<li
						className={classnames('navigation-item', 'dropdown', {
							'navigation-item-more': isMore,
						})}
						ref={dropdownRef}
					>
						<Reference>
							{({ ref }) => (
								<span
									aria-controls={`${rootId}__${id}`}
									aria-expanded={dropdownStatus}
									className={_LinkClass}
									// data-bs-toggle='dropdown'
									// data-bs-target={`#${rootId}__${id}`}
									id={`${rootId}__${id}--link`}
									ref={(node) => setButtonRef(node, ref)}
									role='button'
									tabIndex='-1'
									onClick={dropdownButtonHandleClick}
									onKeyDown={dropdownButtonHandleClick}
								>
									{_Inner}
								</span>
							)}
						</Reference>
						{dropdownStatus && (
							<Popper
								modifiers={[
									{
										name: 'flip',
										options: {
											fallbackPlacements: [`bottom-end`, `bottom-start`],
										},
									},
								]}
								placement='bottom-start'
							>
								{({ ref, style, placement }) => (
									<List
										ariaLabelledby={`${rootId}__${id}--link`}
										className={classnames(
											'dropdown-menu',
											{
												'dropdown-menu-dark': darkModeStatus,
											},
											'show',
										)}
										data-placement={placement}
										id={`${rootId}__${id}`}
										parentId={`${rootId}__${parentId}`}
										ref={(node) => setListRef(node, ref)}
										rootId={rootId}
										style={style}
										onMouseLeave={() => setDropdownStatus(false)}
									>
										{children}
									</List>
								)}
							</Popper>
						)}
					</li>
				</Manager>
			);
		}
		// submenu && in aside
		return (
			<li className='navigation-item'>
				<span
					aria-controls={`${rootId}__${id}`}
					aria-expanded={_active}
					// data-bs-toggle='collapse'
					// data-bs-target={`#${rootId}__${id}`}
					className={_LinkClass}
					id={`${rootId}__${id}--link`}
					role='button'
					tabIndex='-1'
					onClick={handleClick}
					onKeyDown={handleClick}
				>
					{_Inner}
				</span>
				<Collapse isChildClone isOpen={_active}>
					<List
						ariaLabelledby={`${rootId}__${id}--link`}
						id={`${rootId}__${id}`}
						parentId={`${rootId}__${parentId}`}
						rootId={rootId}
						onMouseLeave={closeMenu}
					>
						{children}
					</List>
				</Collapse>
			</li>
		);
	}
	// without submenu
	return <li className='navigation-item'>{_withoutChild}</li>;
};
Item.propTypes = {
	children: PropTypes.node,
	to: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.string,
	id: PropTypes.string,
	parentId: PropTypes.string,
	rootId: PropTypes.string.isRequired,
	isHorizontal: PropTypes.bool,
	notification: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	isMore: PropTypes.bool,
	hide: PropTypes.bool,
};
Item.defaultProps = {
	children: null,
	to: null,
	title: null,
	icon: null,
	id: null,
	parentId: null,
	isHorizontal: false,
	notification: false,
	isMore: false,
	hide: false,
};

export const NavigationLine = ({ className }) => {
	return <hr className={classnames('navigation-line', className)} />;
};
NavigationLine.propTypes = {
	className: PropTypes.string,
};
NavigationLine.defaultProps = {
	className: null,
};

export const NavigationTitle = ({ className, children, ...props }) => {
	return (
		<li className='navigation-item'>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<span className={classnames('navigation-title', className)} {...props}>
				{children}
			</span>
		</li>
	);
};
NavigationTitle.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
NavigationTitle.defaultProps = {
	className: null,
};

const Navigation = forwardRef(
	({ menu, horizontal, id, className, ...props }, ref) => {
		const [activeItem, setActiveItem] = useState(null);

		const { t } = useTranslation('menu');

		function fillMenu(data, parentId, rootId, isHorizontal, isMore) {
			return Object.keys(data).map((item) =>
				data[item].path ? (
					<Item
						activeItem={activeItem}
						hide={data[item].hide}
						icon={data[item].icon}
						id={data[item].id}
						isHorizontal={isHorizontal}
						key={data[item].id}
						notification={data[item].notification}
						parentId={parentId}
						rootId={rootId}
						setActiveItem={setActiveItem}
						title={data[item].text}
						to={data[item].path}
					>
						{!!data[item].subMenu &&
							fillMenu(data[item].subMenu, data[item].id, rootId, isHorizontal)}
					</Item>
				) : (
					!isMore &&
					!isHorizontal && (
						<NavigationTitle key={data[item].id}>
							{t(data[item].text)}
						</NavigationTitle>
					)
				),
			);
		}

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<nav aria-label={id} className={className} ref={ref} {...props}>
				<List horizontal={horizontal} id={id}>
					{fillMenu(menu, id, id, horizontal)}
					{horizontal && (
						<Item
							isHorizontal
							isMore
							icon='MoreHoriz'
							rootId={`other-${id}`}
							title={t('More')}
						>
							{fillMenu(menu, `other-${id}`, `other-${id}`, false, true)}
						</Item>
					)}
				</List>
			</nav>
		);
	},
);
Navigation.propTypes = {
	horizontal: PropTypes.bool,
	menu: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		text: PropTypes.string,
		path: PropTypes.string,
		icon: PropTypes.string,
		isDisable: PropTypes.bool,
		subMenu: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				text: PropTypes.string,
				path: PropTypes.string,
				icon: PropTypes.string,
				isDisable: PropTypes.bool,
			}),
		),
	}).isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	className: PropTypes.string,
};
Navigation.defaultProps = {
	horizontal: false,
	className: null,
};

export default Navigation;
